.App {
  text-align: center;
  background-color: #F1FBE5;
}

.App-logo {
  height: 128px;
  margin-bottom: 16px;
}

.App-header {
  background-color: #F1FBE5;
  padding: 36px;
  padding-bottom: 0px;
  color: #5D7C60;
}

.App-intro {
  margin-top: 0px;
  font-size: large;
  margin-bottom: 0px;
}

.google-play-badge img {
  width: 200px;
  height: auto;
}

.screenshot {
  width: 80%;
  max-width: 300px;
  max-height: 100%;
  margin: 15px;
  border-radius: 16px;
}

h1 {
  font-size: 50px;
}

h1,h2 {
  margin-top: 5px;
  margin-bottom: 5px;
}

h2 {
  font-size: 20px;
  font-weight: normal;
}

.link-unstyled {
  text-decoration: unset;
}

.privacy-terms {
  margin-top: 64px;
  margin-left: auto;
  margin-right: auto;
  max-width: 700px;
  text-align: left;
  color: #5D7C60;
  padding-bottom: 32px;
  margin-bottom: 0px;
}

.privacy-terms h1 {
  font-size: 30px;
}

.Footer {
  background-color: #444;
  padding: 20px;
  color: white;
}
